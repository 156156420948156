import { Field, FieldArray, Form, Formik } from 'formik';
import moment from 'moment';
import { useEffect, useState } from 'react';
import Loading from '../../../SharedComponents/Loading';
import { QueryClient, useMutation, useQuery, useQueryClient } from 'react-query';
import { Link, useParams } from 'react-router-dom';
import miniuser from '../../../assets/img/miniuser.svg';
import RichTextEditor from './RichTextEditor';
import usePermission from '../../../SharedComponents/usePermission';
import { getPage, editPage, getLanguages } from './ApiPage';
import { DateTime } from 'luxon';
import CustomFormField from './CustomFormField';
import PlusCircle from '../../../SharedComponents/Svg/PlusCircle';
import AddThumbnail from './AddThumbnail';
import Sections from '../Sections/Sections';
import ImageContainer from './ImageContainer';
import PagesList from './PagesList';
import AddYoutube from '../../../SharedComponents/AddYoutube';
import Notification from '../../../SharedComponents/Notification';
import { useTranslation } from 'react-i18next';
import BreadCrumbs from '../../../SharedComponents/BreadCrumbs';
import LoadingButton from '../../../SharedComponents/Svg/LoadingButton';
import Flag from '../../../SharedComponents/Svg/Flag';
import LanguageList from '../../../SharedComponents/LanguageList';
import { getCategories } from '../../Categories/ApiCategories';
import ProgramList from './ProgramList';
import InstructorsList from './InstructorsList';
import SpecializationList from './SpecializationList';
import CustomFields from '../Sections/Card/CustomFields';

export default function Page() {
  const params = useParams();
  const id = params.id;
  const [languageId, setLanguageId] = useState(1);
  // const categories = [
  //   { id: 0, name: 'Blogs' },
  //   { id: 1, name: 'News' },
  //   { id: 2, name: 'Events' },
  //   { id: 3, name: 'Projects' },
  //   { id: 7, name: 'اتصل بنا' },
  //   { id: 8, name: 'من نحن' },
  //   { id: 11, name: 'أسئلة شائعة' },
  //   { id: 12, name: 'سياسة الاستخدام' },
  //   { id: 13, name: 'خريطة الموقع' },
  // ];

  let categorystate = {
    pageNumber: 0,
    pageSize: 0,
    isPaging: false,
    langId: 0,
    isSearch: false,
  };
  const CategoriesQuery = useQuery(['categories', categorystate], getCategories);
  const { t, i18n } = useTranslation();
  // Query Data To Fetch Languages
  const languages = useQuery('languages', getLanguages, {
    staleTime: 360000000,
  });
  useEffect(() => {
    setLanguageId(languages.data?.data.sort((a, b) => (a.orderNum > b.orderNum ? 1 : -1))[0].id);
  }, [languages.data]);

  const onCategoryChange = (e) => {
    setCategoryId(parseInt(e.target.value));

    if (e.target.value !== 2) {
      setActiveTab(0);
    }
  };
  useEffect(() => {
    setLanguageId(languages?.data?.data.sort((a, b) => (a.orderNum > b.orderNum ? 1 : -1))[0].id);
  }, [languages.data]);

  const { data, isLoading, isError, refetch } = useQuery(['page'], getPage(id, 0), {
    onSuccess: () => {},
    refetchIntervalInBackground: true,
  });

  // const thumbnail = useQuery(
  //   ['thumbnail']
  // )
  const permissions = usePermission();

  const [activeTab, setActiveTab] = useState(0);

  const mutation = useMutation(editPage, {
    onSuccess: async (data) => {
      if (data.isDone) {
        // window.location.reload();
        refetch();
      }
    },
  });

  const onSubmit = async (e) => {
    delete e.pgPageCategory;
    const newData = {
      ...e,
      uniqueUrl: e.uniqueUrl === null ? e.pgPageLngs[0].title.replace(/\s+/g, '_').toLowerCase() : e.uniqueUrl,
      pageDateString: e.pageDate,
      pgEvent: {
        ...e.pgEvent,
        startDate: e.pgEvent?.startDate,
        endDate: e.pgEvent?.endDate,
        startDatestring: e.pgEvent?.startDate,
        endDatestring: e.pgEvent?.endDate,
      },

      // e.pgPageCategoryId === 2
      //   ? {
      //       ...e.pgEvent,
      //       startDate: null,
      //       endDate: null,
      //       startDatestring: e.pgEvent?.startDate,
      //       endDatestring: e.pgEvent?.endDate,
      //     }
      //   : null,
    };
    try {
      mutation.mutate(newData);
    } catch (error) {}
  };

  const SvgContent = () => {
    return (
      <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
          d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z"
        ></path>
      </svg>
    );
  };
  const SvgSeo = () => {
    return (
      <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
          d="M3 21v-4m0 0V5a2 2 0 012-2h6.5l1 1H21l-3 6 3 6h-8.5l-1-1H5a2 2 0 00-2 2zm9-13.5V9"
        ></path>
      </svg>
    );
  };

  const tabData = [
    {
      svg: <SvgContent />,
      title: 'Content',
    },
    {
      svg: <SvgSeo />,
      title: 'Seo',
    },
  ];

  const handleTab = (e) => {
    console.log(e);
    console.log('clicked');
  };
  const [categoryId, setCategoryId] = useState(0);

  useEffect(() => {
    setCategoryId(data?.data?.pgPageCategoryId);
  }, [categoryId]);
  const initialValues = data?.data;
  const statusId = initialValues?.statusId.toString();
  const startDate = DateTime.fromISO(initialValues?.pgEvent?.startDate).toISODate();
  const endDate = DateTime.fromISO(initialValues?.pgEvent?.endDate).toISODate();
  const pageDate = DateTime.fromISO(initialValues?.pageDate).toISODate();
  const newInitialValues = {
    ...initialValues,
    pageDate: `${pageDate}`,
    isVideo: initialValues?.isVideo === null ? 'false' : 'true',

    pgEvent:
      initialValues?.pgPageCategoryId === parseInt(window.env.REACT_APP_DEFAULT_EVENTSID)
        ? {
            ...initialValues?.pgEvent,
            startDate: startDate,
            endDate: endDate,
          }
        : null,

    statusId,
  };
  const regEx = /^.*(?:(?:youtu\.be\/|v\/|vi\/|u\/\w\/|embed\/)|(?:(?:watch)?\?v(?:i)?=|\&v(?:i)?=))([^#\&\?]*).*/;

  if (isLoading)
    return (
      <div className="relative">
        <Loading />
      </div>
    );
  if (isError) return 'error';

  return (
    <>
      {mutation.isSuccess && <Notification message={t('Page Updated Successfully')} />}
      <header className="flex justify-between items-center">
        <BreadCrumbs to="/pages" name={t('Pages')} />
        <div>
          {/* <AddUser /> */}
          <a
            href={`/pages/create`}
            className={`bg-lightgreen space-x-1 ${
              i18n.language === 'en' ? '' : 'space-x-reverse'
            } text-sm font-bold text-white flex items-center hover:bg-lightgreenhover shadow  px-4 py-2 rounded transition duration-300 ease-in-out`}
          >
            <PlusCircle className="w-5 h-5" /> <span>{t('Create New Page')}</span>
          </a>
        </div>
      </header>
      <div className="my-6">
        <h2 className="text-2xl font-bold">{t('Pages')}</h2>
      </div>
      {data && data?.data && (
        <Formik onSubmit={onSubmit} initialValues={newInitialValues}>
          {({ values, setFieldValue }) => {
            console.log('values', values);
            const url = values?.videoUrl?.match(regEx);
            return (
              <Form>
                <Field className="hidden" type="number" name="id" />
                <div className="grid grid-cols-3 gap-6">
                  <div className="col-span-2 bg-white shadow border rounded p-4 text-gray-600 font-bold">
                    <div className="flex justify-between relative items-center">
                      <div className="flex transform -translate-y-1 relative z-10">
                        {tabData.map((e, index) => (
                          <div
                            key={index}
                            className={`flex justify-center items-center space-x-2 ${
                              i18n.language === 'en' ? '' : 'space-x-reverse'
                            } ${
                              activeTab === index ? 'text-pagetabhover border-pagetabhover' : ''
                            } hover:text-pagetabhover pb-4 px-4 border-b-2 hover:border-pagetabhover transition duration-300 ease-in-out cursor-pointer `}
                            onClick={(e) => setActiveTab(index)}
                          >
                            {e.svg}
                            <span>{t(e.title)}</span>
                          </div>
                        ))}
                        <div
                          key={2}
                          className={`flex justify-center items-center space-x-2 ${
                            data?.data?.pgPageCategoryId === parseInt(window.env.REACT_APP_DEFAULT_EVENTSID)
                              ? ''
                              : 'hidden'
                          } ${
                            activeTab === 2 ? 'text-pagetabhover border-pagetabhover' : ''
                          }   hover:text-pagetabhover pb-4 px-4 border-b-2 hover:border-pagetabhover transition duration-300 ease-in-out cursor-pointer `}
                          onClick={(e) => setActiveTab(2)}
                        >
                          <Flag />
                          <span>{t('Location')}</span>
                        </div>
                      </div>
                      <div>
                        <div>
                          <LanguageList
                            languages={languages.data?.data}
                            languageId={languageId}
                            setLanguageId={setLanguageId}
                            className="flex justify-end transform translate-y-1 relative z-10 mb-4"
                          />
                        </div>
                      </div>
                      <hr className="border w-full absolute top-0 left-0 z-0 mt-11" />
                    </div>
                    <>
                      {values.pgPageLngs.map((page, index) => (
                        <FieldArray
                          key={page.id}
                          name="pgPageLngs"
                          render={(arrayHelpers) => (
                            <>
                              {activeTab === 0 && languageId === page.languageId && (
                                <>
                                  <div className="py-8">
                                    <CustomFormField
                                      label={t('Page Title')}
                                      name={`pgPageLngs[${index}].title`}
                                      fieldValue={values.pgPageLngs[index].title}
                                      setFieldValue={setFieldValue}
                                    />
                                    <CustomFormField
                                      label={t('Description')}
                                      name={`pgPageLngs[${index}].description`}
                                      fieldValue={values.pgPageLngs[index].description}
                                      setFieldValue={setFieldValue}
                                      as="textarea"
                                    />
                                    <RichTextEditor
                                      fieldName={`pgPageLngs[${index}].content`}
                                      fieldValue={values.pgPageLngs[index].content}
                                      setFieldValue={setFieldValue}
                                    />
                                  </div>
                                </>
                              )}
                              {activeTab === 1 && languageId === page.languageId && (
                                <>
                                  <div className="py-8">
                                    <label>
                                      {t('SEO Title')}
                                      <Field
                                        style={{ textIndent: '10px' }}
                                        className="border rounded  w-full mb-8 p-1"
                                        name={`pgPageLngs[${index}].seoTitle`}
                                        type="text"
                                      />
                                    </label>
                                    <label>
                                      {t('SEO Description')}
                                      <Field
                                        style={{ textIndent: '10px' }}
                                        className="border rounded  w-full mb-8 p-1"
                                        name={`pgPageLngs[${index}].seoDesc`}
                                        type="text"
                                      />
                                    </label>
                                    <label>
                                      {t('SEO Keywords')}
                                      <Field
                                        style={{ textIndent: '10px' }}
                                        className="border rounded  w-full mb-8 p-1"
                                        name={`pgPageLngs[${index}].keywords`}
                                        // type='text'
                                        as="textarea"
                                      />
                                    </label>
                                  </div>
                                </>
                              )}
                              {activeTab === 2 && (
                                <div className="py-8">
                                  {values &&
                                    values?.pgEvent?.pgEventLngs.map((event, index) => (
                                      <FieldArray
                                        key={index}
                                        name="pgEvent.pgEventLngs"
                                        render={(arrayHelpers) => (
                                          <>
                                            {languageId === event.languageId && (
                                              <>
                                                <label>
                                                  {t('Place')}
                                                  <Field
                                                    className="border rounded  w-full mb-8 p-1"
                                                    type="text"
                                                    name={`pgEvent.pgEventLngs[${index}].place`}
                                                  />
                                                </label>
                                                <label>
                                                  {t('Organizer')}
                                                  <Field
                                                    className="border rounded  w-full mb-8 p-1"
                                                    type="text"
                                                    name={`pgEvent.pgEventLngs[${index}].organizer`}
                                                  />
                                                </label>
                                              </>
                                            )}
                                          </>
                                        )}
                                      />
                                    ))}
                                  <label>
                                    {t('Location Google Maps Url')}
                                    <Field
                                      className="border rounded  w-full mb-8 p-1"
                                      type="url"
                                      name="pgEvent.location"
                                      onChange={(e) => {
                                        setFieldValue('pgEvent.location', e.target.value);
                                      }}
                                    />
                                  </label>
                                  {values?.pgEvent?.location && (
                                    <iframe
                                      title="map"
                                      src={values?.pgEvent?.location}
                                      width="100%"
                                      height="450"
                                      allowfullscreen=""
                                      loading="lazy"
                                    ></iframe>
                                  )}
                                </div>
                              )}
                            </>
                          )}
                        />
                      ))}
                      <div>
                        <CustomFields setFieldValue={setFieldValue} customFields={values.customFields} />
                      </div>
                      <Sections />
                    </>
                  </div>
                  <div className="space-y-8">
                    {/* Page Options */}
                    <div className="bg-white p-4 rounded shadow">
                      <div
                        className={`border-b-2 text-gray-500 flex space-x-2 ${
                          i18n.language === 'en' ? '' : 'space-x-reverse'
                        } py-2`}
                      >
                        <svg
                          className="w-6 h-6"
                          fill="none"
                          stroke="currentColor"
                          viewBox="0 0 24 24"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                            d="M12 6V4m0 2a2 2 0 100 4m0-4a2 2 0 110 4m-6 8a2 2 0 100-4m0 4a2 2 0 110-4m0 4v2m0-6V4m6 6v10m6-2a2 2 0 100-4m0 4a2 2 0 110-4m0 4v2m0-6V4"
                          ></path>
                        </svg>
                        <span className="font-bold">{t('Page Options')}</span>
                      </div>
                      <div className="my-4 space-y-8 relative text-gray-600">
                        <div className="grid grid-cols-3 mb-2">
                          <label
                            className={`flex items-center space-x-2 ${i18n.language === 'en' ? '' : 'space-x-reverse'}`}
                          >
                            <Field type="radio" name="isVideo" value="false" className="w-4 h-4" />
                            <span>{t('Image')}</span>
                          </label>
                          <label
                            className={`flex items-center space-x-2 ${i18n.language === 'en' ? '' : 'space-x-reverse'}`}
                          >
                            <Field type="radio" name="isVideo" value="true" className="w-4 h-4" />
                            <span>{t('Video')}</span>
                          </label>
                        </div>
                        <Field className={`border rounded hidden w-full p-1 `} name={`imageId`} type="number" />
                        {values.isVideo === 'false' && (
                          <>
                            <div className="h-64 focus:outline-none group rounded border-dashed border-2 mb-4 relative">
                              {(values.imageId && (
                                <>
                                  <ImageContainer imageId={values.imageId} />

                                  {/* <img
                                className='object-fit h-64 w-full'
                                alt='img'
                                src={`${window.env.REACT_APP_IMAGE_PATH}${values.imageId}`}
                              /> */}
                                  <div className="absolute group-hover:opacity-100 opacity-0 inset-0 flex items-center justify-center">
                                    <AddThumbnail edit={true} setField={setFieldValue} />
                                  </div>
                                </>
                              )) || (
                                <div className="absolute inset-0 flex items-center justify-center">
                                  <AddThumbnail setField={setFieldValue} />
                                </div>
                              )}
                            </div>
                            {values.imageId && (
                              <button
                                className="block mb-4 bg-red-700 text-white w-full rounded p-1"
                                type="button"
                                onClick={() => {
                                  setFieldValue('imageId', null);
                                }}
                              >
                                {t('Remove Image')}
                              </button>
                            )}
                          </>
                        )}
                        {values.isVideo === 'true' && (
                          <>
                            <div className="h-64 focus:outline-none group rounded border-dashed border-2 mb-4 relative">
                              {(values.videoUrl && (
                                <>
                                  <iframe
                                    style={{ width: '100%' }}
                                    height="315"
                                    src={`https://www.youtube.com/embed/${url[1]}`}
                                    title="video"
                                    frameBorder="0"
                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                    allowFullScreen
                                  ></iframe>
                                  <div className="absolute group-hover:opacity-100 opacity-0 inset-0 flex items-center justify-center">
                                    <AddYoutube edit={true} setField={setFieldValue} />
                                  </div>
                                </>
                              )) || (
                                <div className="absolute inset-0 flex items-center justify-center">
                                  <AddYoutube edit={true} setField={setFieldValue} />
                                </div>
                              )}
                            </div>
                          </>
                        )}

                        <CustomFormField
                          name="uniqueUrl"
                          setFieldValue={setFieldValue}
                          label={t('uniqueUrl')}
                          className="border rounded  w-full mb-0 p-1"
                          fieldValue={values.uniqueUrl}
                        />
                        <div>
                          <PagesList setFieldValue={setFieldValue} parentId={values.parentId} languageId={languageId} />
                        </div>
                        <div className="grid grid-cols-3">
                          <label
                            className={`flex items-center space-x-2 ${i18n.language === 'en' ? '' : 'space-x-reverse'}`}
                          >
                            <Field type="radio" name="statusId" value="1" className="w-4 h-4" />
                            <span>{t('Published')}</span>
                          </label>
                          <label
                            className={`flex items-center space-x-2  ${
                              i18n.language === 'en' ? '' : 'space-x-reverse'
                            }`}
                          >
                            <Field type="radio" name="statusId" value="0" className="w-4 h-4" />
                            <span>{t('Draft')}</span>
                          </label>
                        </div>

                        <div>
                          <Field
                            className="border rounded py-1 px-2 w-full"
                            as="select"
                            name="pgPageCategoryId"
                            onChange={(e) => {
                              setFieldValue('pgPageCategoryId', parseInt(e.target.value));
                              onCategoryChange(e);
                            }}
                          >
                            {/* {categories.map((category) => (
                              <option value={category.id} key={category.id}>
                                {t(category.name)}
                              </option>
                            ))} */}

                            {CategoriesQuery.data?.data.map((category) => {
                              let categoryname = category.pgPageCategoryLngs.filter((e) => {
                                if (e.languageId === languageId) {
                                  return e.name;
                                }
                                return '';
                              });
                              return (
                                <option value={parseInt(category.id)} key={category.id}>
                                  {categoryname[0]?.name}
                                  {/* {t(category.name)} */}
                                </option>
                              );
                            })}
                          </Field>
                          {/* {values.pgPageCategoryId === parseInt(window.env.REACT_APP_DEFAULT_EVENTSID) && (
                            <div className="flex flex-col space-y-4 mt-4 bg-gray-50 p-4 rounded">
                              <ProgramList
                                setFieldValue={setFieldValue}
                                programId={values.programId}
                                languageId={languageId}
                              />
                              <InstructorsList
                                setFieldValue={setFieldValue}
                                instructorId={values.instructorId}
                                languageId={languageId}
                              />
                              <SpecializationList
                                setFieldValue={setFieldValue}
                                specializationId={values.specializationId}
                                languageId={languageId}
                              />
                            </div>
                          )} */}
                          <label className={`my-4 block `}>
                            {t('Date')}
                            <Field className="w-full border p-2 rounded " type="date" name="pageDate" />
                          </label>
                          {(values.pgPageCategoryId === 14 ||
                            values.pgPageCategoryId === parseInt(window.env.REACT_APP_DEFAULT_EVENTSID)) && (
                            <>
                              <div
                                className={`flex justify-between py-4 ${
                                  i18n.language === 'en' ? '' : 'space-x-reverse'
                                } space-x-1`}
                              >
                                <label className="w-1/2">
                                  {t('From')}
                                  <Field
                                    className="w-full border  p-0 py-1 2xl:p-3 rounded"
                                    type="date"
                                    name="pgEvent.startDate"
                                    min={new Date().toString()}
                                  />
                                </label>
                                <label className="w-1/2">
                                  {t('To')}
                                  <Field
                                    className="w-full border   p-0 py-1  2xl:p-3  rounded"
                                    type="date"
                                    name="pgEvent.endDate"
                                    min={values?.pgEvent?.startDate}
                                    disabled={!values?.pgEvent}
                                  />
                                </label>
                              </div>
                              <div
                                className={`flex justify-between py-4 ${
                                  i18n.language === 'en' ? '' : 'space-x-reverse'
                                } space-x-1`}
                              >
                                <label className="w-1/2">
                                  {t('From')} Time
                                  <Field
                                    className="w-full border  p-0 py-1 2xl:p-3 rounded"
                                    type="time"
                                    name="pgEvent.timeFrom"
                                    // min={new Date().toString()}
                                  />
                                </label>
                                <label className="w-1/2">
                                  {t('To')} Time
                                  <Field
                                    className="w-full border   p-0 py-1  2xl:p-3  rounded"
                                    type="time"
                                    name="pgEvent.timeTo"
                                    min={values?.pgEvent?.timeTo}
                                    // disabled={!values?.pgEvent}
                                  />
                                </label>
                              </div>
                            </>
                          )}
                        </div>
                        <div>
                          <label
                            className={`flex items-center space-x-2 ${i18n.language === 'en' ? '' : 'space-x-reverse'}`}
                          >
                            <Field type="checkbox" name="showOnSidebar" className="w-4 h-4" />
                            <span>{t('Show Sidebar')}</span>
                          </label>
                        </div>
                        <div>
                          <label
                            className={`flex items-center space-x-2 ${i18n.language === 'en' ? '' : 'space-x-reverse'}`}
                          >
                            <Field type="checkbox" name="showAuthor" className="w-4 h-4" />
                            <span>{t('Show Author')}</span>
                          </label>
                        </div>
                        <div>
                          <label
                            className={`flex items-center space-x-2 ${i18n.language === 'en' ? '' : 'space-x-reverse'}`}
                          >
                            <Field type="checkbox" name="showDate" className="w-4 h-4" />
                            <span>{t('Show Date')}</span>
                          </label>
                        </div>
                      </div>
                      <div className={`justify-end flex space-x-4 ${i18n.language === 'en' ? '' : 'space-x-reverse'}`}>
                        <button className="bg-pink hover:bg-pinkhover text-white px-4 py-2 rounded">
                          {t('Preview')}
                        </button>

                        <button
                          type="submit"
                          className="bg-darkblue hover:bg-darkbluehover text-white px-4 py-2 rounded"
                        >
                          {t('Update')}
                          {mutation.isLoading && <LoadingButton />}
                        </button>
                      </div>
                    </div>

                    <div className="bg-white p-4 rounded shadow">
                      <div
                        className={`border-b-2 text-gray-500 flex space-x-2 ${
                          i18n.language === 'en' ? '' : 'space-x-reverse'
                        } py-2`}
                      >
                        <svg
                          className="w-6 h-6"
                          fill="none"
                          stroke="currentColor"
                          viewBox="0 0 24 24"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                            d="M19 11H5m14 0a2 2 0 012 2v6a2 2 0 01-2 2H5a2 2 0 01-2-2v-6a2 2 0 012-2m14 0V9a2 2 0 00-2-2M5 11V9a2 2 0 012-2m0 0V5a2 2 0 012-2h6a2 2 0 012 2v2M7 7h10"
                          ></path>
                        </svg>
                        <span className="font-bold">{t('Page History')}</span>
                      </div>
                      <div className="my-4 space-y-4 relative">
                        <hr
                          className=" bg-gray-200 h-full  w-1 absolute left-0 top-0 bottom-0 ml-4 z-0"
                          // style={{ height: '85%', marginTop: 40 }}
                        />
                        {/* Modified By */}
                        {data?.data?.modifyDate && (
                          <div className="flex justify-between items-center z-10 relative">
                            <div
                              className={`flex text-gray-500 space-x-4 ${
                                i18n.language === 'en' ? '' : 'space-x-reverse'
                              }`}
                            >
                              <img className="bg-white" src={miniuser} />
                              <div>
                                <span>
                                  {t('Modified by')} <b className="text-gray-700">{data?.data?.modifiedByName}</b>
                                </span>
                                <small className={`flex space-x-1 ${i18n.language === 'en' ? '' : 'space-x-reverse'}`}>
                                  <svg
                                    className="w-4 h-4"
                                    fill="none"
                                    stroke="currentColor"
                                    viewBox="0 0 24 24"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                      strokeWidth="2"
                                      d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z"
                                    ></path>
                                  </svg>{' '}
                                  <span>{moment(data?.data?.modifyDate).format('DD/MM/YYYY  hh-mm ')}</span>
                                </small>
                              </div>
                            </div>
                            <div></div>
                          </div>
                        )}{' '}
                        {/* Created By */}
                        <div className="flex justify-between items-center z-10 relative">
                          <div
                            className={`flex text-gray-500 space-x-4 ${
                              i18n.language === 'en' ? '' : 'space-x-reverse'
                            }`}
                          >
                            <img className="bg-white" src={miniuser} />
                            <div>
                              <span>
                                {t('Created by')} <b className="text-gray-700">{data?.data?.createdByName}</b>
                              </span>
                              <small className={`flex space-x-1 ${i18n.language === 'en' ? '' : 'space-x-reverse'}`}>
                                <svg
                                  className="w-4 h-4"
                                  fill="none"
                                  stroke="currentColor"
                                  viewBox="0 0 24 24"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth="2"
                                    d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z"
                                  ></path>
                                </svg>{' '}
                                <span>{moment(values.createDate).format('DD/MM/YYYY  hh-mm ')}</span>
                              </small>
                            </div>
                          </div>
                          <div></div>
                        </div>
                      </div>
                      {/* <div className='text-right'>
                        <button className='bg-orange hover:bg-orangehover text-white px-4 py-2 rounded'>
                          Show All
                        </button>
                      </div> */}
                    </div>
                  </div>
                </div>
              </Form>
            );
          }}
        </Formik>
      )}{' '}
    </>
  );
}
